<template>
  <v-container fluid class="px-6">

    <v-card>

      <v-card-title class="px-6 card-header white--text">
        <v-icon class="mr-3 white--text">mdi-format-list-bulleted</v-icon> <h3>List of Parameter</h3>
      </v-card-title>

      <v-row class="pt-4 pl-3 pr-7">
        <v-col>
          <v-select
          v-on:change="selectValueStation"
          :items="itemStation"
          label="Station"
          class="mx-4 text--text"
          required
          outlined 
          dense
          prepend-inner-icon="mdi-store-24-hour"
          ></v-select>
        </v-col>
        <v-col>
          <v-text-field
          v-model="search"
          label="Search"
          single-line
          hide-details
          outlined
          dense
          autocomplete="off"
          class="shrink text--text"
          prepend-inner-icon="mdi-magnify"
        ></v-text-field>
        </v-col>
      </v-row>

      <div style="max-height: calc(100vh - 300px);overflow-y:auto;">
        <v-data-table
          :headers="theadParamList"
          :items="itemsWithIndexParam"
          :search="search"
          class="elevation-2 px-6 headerDtSarawak"
          fixed-header
          height="auto"
          :loading="this.loadingTable"
          :loading-text="this.loadingText"
        >
        </v-data-table>
      </div>

    </v-card>

    

  </v-container>
</template>

<script>
import axios from 'axios'

export default {
  data: () => ({
        editDialog: false,
        dialogDelete: false,
        delSelected: '',

        itemStation: [],
        valStation: null,

        dataStation: [],

        loadingTable: false,
        loadingText: "",

        theadParamList: [
          { text: 'NO.', sortable: false, value: 'index' },
          { text: 'PARAMETER', sortable: false, value: 'fullname' },
          { text: 'ACRONYM', sortable: false, value: 'acronym' },
          { text: 'UNIT', sortable: false, value: 'unit' },
          // { text: 'Action', sortable: false, value: 'actions' }
        ],
        tbodyParamList: [],
        search: '',

  }),

  methods: {

    getListStation(){
        axios.get(this.globalUrl+'bakaj/stations', {
            headers: {
                'Authorization': 'Bearer ' + this.$store.getters.user.token,
            }
        })
        .then((response) => {
            console.log(response.data);
            this.itemStation = [];

            for (let i = 0; i < response.data.length; i++) {
              this.itemStation.push(response.data[i].stationId + " - " + response.data[i].location);
            }

            this.dataStation = response.data;
            
        })
        .catch(error => {
            console.log(error);
        })
    },

    // Get value from Selected Station
    selectValueStation: function(e) {

        this.loadingTable = true;
        this.loadingText = "Data Loading... Please wait";

        this.valStation = e.split(" - ")[0];
        console.log(this.valStation);

        this.tbodyParamList = [];
        this.getAllParams(this.valStation)
    },


    getAllParams(station){
        axios.get(this.globalUrl+"bakaj/params?stationid="+station, {
            headers: {
                Authorization: 'Bearer ' + this.$store.getters.user.token,
            },
        })
        .then((response) => {

            let data = response.data;
            // this.tbodyParamList = response.data
            // console.log(this.tbodyParamList)

            console.log(data);

            let allDataStationSelected = [];
            let dataDisplayTable = [];

            for (let k = 0; k < this.dataStation.length; k++) {
              if (this.dataStation[k].stationId == station) {
                allDataStationSelected.push(this.dataStation[k]);
              }
            }

            console.log(allDataStationSelected);

            let keyData = Object.keys(allDataStationSelected[0])
            // console.log(keyData);

            for (let i = 0; i < data.length; i++) {
              for (let x = 0; x < keyData.length; x++) {
                // console.log(keyData[x].split("_ACTIVE")[0]);
                if (data[i].reading == allDataStationSelected[0][keyData[x]] || data[i].reading == "_"+allDataStationSelected[0][keyData[x]]) {
                  // console.log(data[i].reading);
                  dataDisplayTable.push(data[i]);
                }
              }
            }

            console.log(dataDisplayTable);
            this.tbodyParamList = dataDisplayTable
            this.loadingTable = false;
            this.loadingText = "";
        })
        .catch((error) => {
            console.log(error)
        })
    },

  },

  computed: {
    itemsWithIndexParam() {
      return this.tbodyParamList.map((tbodyParamList, index) => ({
        ...tbodyParamList,
        index: index + 1,
      }));
    }
  },

  mounted() {
    this.getListStation();
    // this.getAllParams()
  }
}
</script>

<style lang="scss">

.v-data-table__wrapper > table > thead > tr > th {
  background-color: #004892 !important;
  color: white !important;
  font-size: 14px !important;
}

</style>